const urls = {
  newsLetters: "/news-letters",
  cadre: {
    baseUrl: "/cADRE",
    products: "/cADRE/products",
    associates: {
      baseUrl: "/cADRE/associates",
    },
    reservations: {
      reserve: "/cADRE/reservations/reserve",
      baseUrl: "/cADRE/reservations",
    },
  },
};

export default urls;
