import { useEffect, useRef, useState } from "react";
import Card from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../css/home.css";
import check from "../assets/svg/check.svg";
import h01 from "../assets/svg/hiw/01.svg";
import h02 from "../assets/svg/hiw/02.svg";
import h03 from "../assets/svg/hiw/03.svg";
import h04 from "../assets/svg/hiw/04.svg";

import f01 from "../assets/svg/frch/01.svg";
import f02 from "../assets/svg/frch/02.svg";
import f03 from "../assets/svg/frch/03.svg";
import f04 from "../assets/svg/frch/04.svg";
import youtube from "../assets/svg/logos_youtube.svg";
import facebook from "../assets/svg/logos_facebook.svg";
import Faqs from "../components/Faqs";
import Hero from "../components/Hero";

import ReserveForm from "../components/ReserveForm";
import ShareSite from "../components/ShareSite";
import ScrollTop from "../components/ScrollTop";

const steps = [
  "Pick the product/technology",
  "Select an associate/startup estate",
  "Make a hassle-free online reservation payment",
  "Complete transaction online",
  "Safe, secure and hassle-free means of buying your",
  "Manufacturing franchise business and become your own boss",
];
const benefits = [
  "From the comfort of your home, you can browse through products for manufacturing alongside current promos, and you need not wait for anyone to contact you or have any conversations; you can just buy the franchise and get going.",

  "You need not go to the bank branch or any startup estate to pay your reservation.",
  "An online reservation exempts you from paying cATT’s transportation, registration, & insurance charges if there are any.",
];

const what2know = [
  "You qualify for no cost cATT facility asset and registration, insurance, & operational, & maintenance charges shall be borne by you periodically if any",
  "The lead time to hand over cATT is two to five months from the date of the e-signed agreement",
  "Your investment will grow, and your ROI will look as if it is on a 23% CAGR(5yr) portfolio; your investment growth will be under the watchful and vigilant eyes of our AI system",
];
function Reservation(props) {
  const [isTopVisible, setIsTopVisible] = useState(false);
  const cntRef = useRef(null);
  const handleScroll = () => {
    if (cntRef.current.scrollTop > window.innerHeight && !isTopVisible) {
      setIsTopVisible(true);
      return;
    }

    if (cntRef.current.scrollTop <= 100 && isTopVisible) {
      setIsTopVisible(false);
    }
  };

  useEffect(() => {
    cntRef?.current.addEventListener("scroll", handleScroll);
    return () => {
      cntRef?.current.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="hpage">
      {/* <Header /> */}
      <div ref={cntRef} className="hcontent noscroll ubuntu">
        <Hero />
        <div className="container cnp">
          <Card title="Reserve Your Franchise Online">
            <p style={{ textAlign: "center" }}>
              Start booking your new manufacturing franchise business from the
              comfort <br className="desktop" /> of your home!
            </p>
            <ul>
              {steps.map((i, idx) => (
                <li key={idx}>
                  <img src={check} alt={i} />
                  <p>{i}</p>
                </li>
              ))}
            </ul>
            <h2>Online Reservation</h2>
            <p>
              Using this online reservation facility, you can reserve your
              franchise and begin buying a franchise without going from pillar
              to post. The online reservation is deductible from the final
              balance of your franchise fee and is valid for a thirty (30) day
              period. Final payments may be transacted remotely or in person
              through cash/cheque payment.
            </p>
          </Card>
          <Card title="Benefits Of Reserving Online">
            <ul>
              {benefits.map((i, idx) => (
                <li key={idx}>
                  <img src={check} alt={i} />
                  <p>{i}</p>
                </li>
              ))}
              <li>
                <img
                  src={check}
                  alt={
                    "Easy refund from www.cspillai.com with no complications."
                  }
                />
                <p>
                  Easy refund from{" "}
                  <a
                    href="http://www.cspillai.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.cspillai.com
                  </a>{" "}
                  with no complications.
                </p>
              </li>
            </ul>
          </Card>
          <Card title="Reservation Validity">
            <p>
              Reservation payments are valid for up to thirty (30) days. This
              means that you must be in the process of completing your
              transaction before this window expires. Reservations that remain
              inactive after thirty (30) days will be subject to being
              cancelled. If you have made a reservation through promotion and
              the down payment was not made before the promo expiry date, then
              discounts may be subject to change. In either of the above cases,
              you can claim a refund.
            </p>
          </Card>
          <Card title="Refund Policy">
            <p>
              Reservation payments are refundable after thirty (30) days from
              the transaction date. Contact refunds@cspillai.com for more
              information. View terms & conditions
            </p>
          </Card>
          <Faqs />
          <Card id="hiw1" title="How does it work?">
            <p>
              You can now reserve your franchise opportunity online without
              going from pillar to post!
            </p>
            <div className="flex justif-center mbl">
              <div className="dis_c">
                <img src={f01} alt="Provide personal details" />
                <span>Provide personal details</span>
              </div>
              <div className="dis_c">
                <img src={f02} alt="Make payment" />
                <span>Make payment</span>
              </div>
              <div className="dis_c">
                <img
                  src={f03}
                  alt="Your mentor or associate will be notified of your payment"
                />
                <span>
                  Your mentor or associate will be notified of your payment
                </span>
              </div>
              <div className="dis_c">
                <img
                  src={f04}
                  alt="Your mentor or associate will begin to mentor you"
                />
                <span>Your mentor or associate will begin to mentor you</span>
              </div>
            </div>
          </Card>
          <Card title="What you need to know">
            <ul>
              {what2know.map((i, idx) => (
                <li key={idx}>
                  <img src={check} alt={i} />
                  <p>{i}</p>
                </li>
              ))}
              <li>
                <img
                  src={check}
                  alt={
                    "We have a dedicated support team and if you have questions, send us an email at support.care@cspillai.com"
                  }
                />
                <p>
                  We have a dedicated support team and if you have questions,
                  send us an email at{" "}
                  <a href="mailto: support.care@cspillai.com">
                    {" "}
                    support.care@cspillai.com
                  </a>
                </p>
              </li>
            </ul>
            <div className="flex logos">
              <a
                href="http://youtube.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="" />
              </a>
              <a
                href="https://web.facebook.com/CspillaiVentures/?_rdc=1&_rdr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="" />
              </a>
            </div>
          </Card>
          <ReserveForm />
          <Card id="hiw2" title="How does it work?">
            <div className="cds">
              <div className="cd">
                <img
                  src={h01}
                  alt="Open manufacturingbymasses.cspillai.com and use the ‘Enquire’
                  & ‘Get started’ buttons to start your franchise buying
                  journey. As a first thing, submit the Enquiry Form and get
                  guided by the system."
                />
                <h2>Visit Service Site</h2>
                <p>
                  Open{" "}
                  <a
                    href="http://manufacturingbymasses.cspillai.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    manufacturingbymasses.cspillai.com
                  </a>{" "}
                  and use the ‘Enquire’ & ‘Get started’ buttons to start your
                  franchise buying journey. As a first thing, submit the Enquiry
                  Form and get guided by the system.
                </p>
              </div>
              <div className="cd">
                <img
                  src={h02}
                  alt="Fill out the Manufacturing Franchise Agreement(MFA), preview,
                  make sure everything is alright, submit, & let the company
                  guide your e-signing process."
                />
                <h2>Sign-off MFA</h2>
                <p>
                  Fill out the Manufacturing Franchise Agreement(MFA), preview,
                  make sure everything is alright, submit, & let the company
                  guide your e-signing process.
                </p>
              </div>
              <div className="cd">
                <img
                  src={h03}
                  alt="Complete the online payment using the link: Here and use Send button to submit the payment details to the system."
                />
                <h2>Make Payment</h2>
                <p>
                  Complete the online payment using the link:{" "}
                  <a
                    href="http://manufacturingbymasses.cspillai.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>{" "}
                  and use Send button to submit the payment details to the
                  system.
                </p>
              </div>
              <div className="cd">
                <img
                  src={h04}
                  alt="Upload MFA to your account to notify stakeholders of your payment; beget cATT at no cost and let the support mechanism kickstart."
                />
                <h2>Notify Stakeholders</h2>
                <p>
                  Upload MFA to your account to notify stakeholders of your
                  payment; beget cATT at no cost and let the support mechanism
                  kickstart.
                </p>
              </div>
            </div>
            <a
              href="http://manufacturingbymasses.cspillai.com/get-started"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="btn_st">Start your buying journey</div>
            </a>
          </Card>
          <ShareSite />
        </div>
        <Footer />
      </div>
      <ScrollTop visible={isTopVisible} />
    </div>
  );
}

export default Reservation;
