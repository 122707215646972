import React from "react";

function Button({ title, className, type = "button", loading, ...props }) {
  return (
    <button {...props} type={type} className={`btn btn-primary ${className}`}>
      {loading ? "Loading..." : title}
    </button>
  );
}

export default Button;
