import React from "react";

export default function Error({ error, touched, style = {} }) {
  if (!touched || !error) return null;
  return (
    <span style={styles.error}>{error.replace("NaN", "Not a Number")}</span>
  );
}

const styles = {
  error: {
    color: "#ff4081",
    fontSize: 12,
    marginBottom: 10,
    paddingLeft: 5,
  },
};
