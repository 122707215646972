import React, { useState } from "react";
import Card from "./Card";
import plus from "../assets/svg/plus.svg";

const faqs = [
  {
    title: "Why make an online reservation payment?",
    body: "The company receives many partner inquiries every month. By submitting an online reservation payment, you can ensure a prioritized purchase journey, avoid unnecessary waiting times at the allotment and stay clear of disappointments regarding franchise availability. Best of all, you can navigate the franchise buying process from the comfort of your smartphone.",
  },
  {
    title: "Is my reservation payment deductible?",
    body: "Yes, your reservation fee will be deducted from the franchise fee when you complete the transaction online.",
  },
  {
    title: "Why is there a 30-day valid period?",
    body: "We cannot indefinitely hold your reservation, which is why there is an expiry. It also indicates to us that you are serious and ready to buy and enables the company to prioritize your reservation request. Also, cATT availability and promos can regularly change beyond 30 days.",
  },
  {
    title: "What methods of payment can I choose to complete my transaction?",
    body: "You may complete your transaction through either cash/cheque or bank to bank transfer.",
  },
  {
    title: "Will I definitely get to buy the franchise that I reserve online?",
    body: "The majority of the time you will get, but there are occasions where the cATT will be out of stock, so at that point, an associate will offer you a cATT currently in stock or provide the timeline on when the cATT unit will be available. If neither of those options works for you then you can claim a refund.",
  },
];

const RenderFAQ = ({ title, body }) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <Card className="cdc" style={{ padding: "0%" }}>
      <div onClick={() => setIsOpened(!isOpened)} className="head">
        <p>{title}</p>
        <img
          style={{ transform: isOpened ? "rotateZ(45deg)" : "rotate(0deg)" }}
          src={plus}
          alt={title}
        />
      </div>
      {isOpened && <p style={{ padding: 30, paddingTop: 0 }}>{body}</p>}
    </Card>
  );
};

function Faqs(props) {
  return (
    <div style={{ marginBottom: "10%" }} className="faqs">
      <h2 className="stitle">Reservation FAQs</h2>
      {faqs.map((p, idx) => (
        <RenderFAQ key={idx} {...p} />
      ))}
    </div>
  );
}

export default Faqs;
