import React, { useState } from "react";
import "../css/footer.css";
import home from "../assets/svg/home.svg";
import SocialIcons from "./Socials";
import useClient from "../hooks/useClient";
import urls from "../api/urls";
import chv from "../assets/chv.png";

const usefulllinks = [
  {
    title: "csPILLAI",
    to: "https://cspillai.com",
  },
  {
    title: "Invest2Exit",
  },
  {
    title: "Invest2Multiply",
    to: "https://fundingbyprivateequity.cspillai.com",
  },
  {
    title: "Invest2Enterprise",
    to: "https://manufacturingbymasses.cspillai.com",
  },
  {
    title: "Invest2Excel",
  },
  {
    title: "Sponsor",
    to: "https://cspillai.com/sponsor",
  },
  {
    title: "Token",
    to: "https://cspillai.com/sponsor/token",
  },
];

const polices = [
  {
    title: "Privacy Policy",
  },
  {
    title: "Terms Of Service",
  },
  {
    title: "Earnings Disclaimer",
  },
  {
    title: "Terms and Conditions",
  },
  {
    title: "Copyright Notice",
  },
  {
    title: "Refund Policy",
  },
];

function Footer(props) {
  const [email, setEmail] = useState("");
  const { post, isLoading } = useClient();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) return;
    const res = await post(urls.newsLetters, {
      email,
    });
    console.log(res);
    if (res.error) return alert(res.error?.message || "An error occured");
    setEmail("");
    alert(res.data.message);
  };
  return (
    <footer className="lato container">
      <div className="ftfx">
        <div className="fcnt">
          <h2 className="poppins cname">csPILLAI</h2>
          <p>
            Business & Startup Investing Online Platform!br WE BUILD & SELL
            START-UPS, SCALE-UPS, ENTREPRENEURSHIPS, AND ASSOCIATESHIPS!
          </p>
          <SocialIcons />
        </div>
        <div className="fcnt">
          <h3 className="sbtitle">Usefull Links</h3>
          <ul>
            {usefulllinks.map((l, idx) => (
              <a
                key={idx}
                href={l.to}
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>
                  <img
                    style={{ marginRight: 10 }}
                    src={chv}
                    alt={l.title}
                    className="desktop"
                  />
                  {l.title}
                </li>
              </a>
            ))}
          </ul>
        </div>
        <div className="fcnt">
          <h3 className="sbtitle">Our Policies</h3>
          <ul>
            {polices.map((l, idx) => (
              <a
                key={idx}
                href={`https://cspillai.com/lega/${l.title
                  .toLowerCase()
                  .replace(" ", "-")
                  .replace(" ", "-")
                  .replace(" ", "-")}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>
                  <img
                    style={{ marginRight: 10 }}
                    src={chv}
                    alt={l.title}
                    className="desktop"
                  />
                  {l.title}
                </li>
              </a>
            ))}
          </ul>
        </div>
        <div className="fcnt">
          <h3 className="subtitle">Subscribe to our Newsletter</h3>
          <form
            onSubmit={handleSubmit}
            action="#"
            className="nform"
            method="post"
          >
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              type="email"
              name="name"
              id="email"
              placeholder="Your Email"
            />
            <input type="submit" value={isLoading ? "Loading..." : "Submit"} />
          </form>
        </div>
      </div>
      <div className="flex address">
        <img src={home} alt="csPILLAI Address" />
        <p>
          10th Floor, Tower 1, Seawoods Grand Central, Sector 40, Seawoods
          Railway Station, Nerul Node, Mumbai, MH 400706
        </p>
      </div>
      <p className="note">
        Nothing on this website should be defined as an offer to sell, an act of
        asking to buy an offer, or a proposal for any assurance by csPILLAI or
        any third party. You are singularly responsible for judging whether any
        purchase/investment strategy, safety/security/assurance, or related
        purchase/investment is proper for you based on your investment goals,
        financial situations, and uncertainty threshold. Investors and users
        should discuss judicial, tax, insurance, or financing advice with
        authorized legal specialists and finance advisors. Would you mind
        emailing for additional bits of advice?
      </p>
      <div className="copy">Copyright © 2021 csPILLAI All Rights Reserved.</div>
    </footer>
  );
}

export default Footer;
