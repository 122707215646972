import React from "react";
import Select from "../components/Select";
import { useFormikContext } from "formik";
import Error from "./Error";
import Check from "../components/Check";

export default function FormSelect({
  name,
  value,
  check,
  many = false,
  onChange = () => {},
  setNull,
  ...props
}) {
  const { setFieldTouched, errors, touched, setFieldValue, values } =
    useFormikContext();
  return (
    <>
      {check ? (
        <Check
          checked={values[name] === value}
          handleChange={() => {
            setFieldTouched(name, true);
            setFieldValue(name, value);
          }}
          value={value}
          {...props}
        />
      ) : (
        <Select
          {...props}
          remove={(id) =>
            setFieldValue(
              name,
              values[name].filter((v) => v !== id)
            )
          }
          values={many ? values[name] : []}
          onBlur={() => setFieldTouched(name)}
          onChange={(e) => {
            if (many) {
              if (values[name].includes(e.value)) return;
              setFieldValue(name, [...values[name], e.value]);
            } else {
              onChange(e);
              if (values[name] !== e.value && setNull) {
                setFieldValue(setNull, null);
              }
              setFieldValue(name, e.value);
            }
          }}
        />
      )}
      <Error error={errors[name]} touched={touched[name]} />
    </>
  );
}
