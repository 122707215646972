import React, { useState } from "react";
import "../css/hero.css";
import check from "../assets/svg/check.svg";

import cATT01 from "../assets/cATT/cATT01.png";
import logo from "../assets/logotrasparent.png";
import mfa from "../assets/MFA.pdf";

function Hero(props) {
  const [isNavOpened, setIsNavOpened] = useState(false);
  return (
    <div id="home" className="hero">
      <nav className={`${isNavOpened ? "opened" : ""}`}>
        <ul>
          <li>Home</li>
          <li>Home2</li>
          <li>Home3</li>
          <li>Home4</li>
        </ul>
        <div onClick={() => setIsNavOpened(false)}></div>
      </nav>
      <img className="water_mark_catt" src={cATT01} alt="" />
      <img className="water_mark_catt desktop logo_water" src={logo} alt="" />
      {/* <div className="menu mobile">
        <div onClick={() => setIsNavOpened(true)} className="bugger">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div> */}
      <h2>
        RESERVE (and/or) <br className="mobile" />
        BUY <br className="desktop" />
        FRANCHISE
      </h2>
      <div className="cnt flex justify-between">
        <div className="card">
          <div className="flex align-center li">
            <img src={check} alt="" />
            <span>Select Product</span>
          </div>
          <div className="flex align-center li">
            <img src={check} alt="" />
            <span>Make reservation online</span>
          </div>
          <div className="flex align-center li">
            <img src={check} alt="" />
            <span>No hassle refund if you don't buy</span>
          </div>

          <span className="it">
            Buy your new franchise online
            <br />
            Reserve for
          </span>

          <span className="pr poppins"> Rs. 999</span>

          <button
            onClick={() =>
              document
                .getElementById("reserveForm")
                .scrollIntoView({ behavior: "smooth" })
            }
            className="btn btn-orange sans"
          >
            Reserve Your Franchise
          </button>
          <br />
          <span
            onClick={() =>
              document
                .getElementById("hiw1")
                .scrollIntoView({ behavior: "smooth" })
            }
            className="hk"
          >
            How does it work?
          </span>

          <div className="btns">
            <a
              href={mfa}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white" }}
            >
              <button style={{ color: "white" }} className="btn sans">
                Franchise agreement
              </button>
            </a>
            <a
              href="http://manufacturingbymasses.cspillai.com/get-started"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn btn-outline sans">
                Buy your franchise
              </button>
            </a>
          </div>

          <span
            onClick={() =>
              document
                .getElementById("hiw2")
                .scrollIntoView({ behavior: "smooth" })
            }
            className="hk"
          >
            How does it work?
          </span>
        </div>
        <div className="catt">
          <div className="txt">Solar roof top is an optional extra</div>
          <div className="imgs">
            <img src={cATT01} alt="cspillai cATT" />
            <img src={cATT01} alt="cspillai cATT" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
