import AppContext from "./contexts/AppContext";
import Reservation from "./pages/Reservation";
import { useState } from "react";
import Home from "./pages/Home";

function App() {
  const [user, setUser] = useState(null);
  return (
    // <BroserRouter
    <AppContext.Provider value={{ user, setUser }}>
      <Reservation />
    </AppContext.Provider>
  );
}

export default App;
