import React, { useEffect, useState } from "react";
import "../css/input.css";
import arrow from "../assets/svg/arrow.svg";

function Select({
  label,
  placeholder,
  showSelected = true,
  style = {},
  options = [],
  onBlur = () => {},
  onChange = () => {},
  values = [],
  remove = () => {},
  refresh = false,
}) {
  const [selected, setSelected] = useState(null);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (refresh) {
      setSelected(null);
    }
  }, [options]);

  return (
    <div style={style} className="input">
      <span className="lbtx">{label}</span>
      <div
        onClick={() => {
          setIsOpened(!isOpened);
          onBlur();
        }}
        className="select_div"
      >
        <span className="pholder">
          {selected && showSelected ? selected.label : placeholder}
        </span>
        <img
          style={{ transform: isOpened ? "rotate(180deg)" : "rotate(0deg)" }}
          src={arrow}
          alt={placeholder}
        />
      </div>
      {isOpened && (
        <div className="options">
          {options.length === 0 ? (
            <h2>No Options</h2>
          ) : (
            <>
              {options.map((s, idx) => (
                <h2
                  onClick={() => {
                    setSelected(s);
                    setIsOpened(false);
                    onChange(s);
                  }}
                  key={idx}
                >
                  {s.label}
                </h2>
              ))}
            </>
          )}
        </div>
      )}
      <div className="selected">
        {values.map((v, idx) => {
          const item = options.find((o) => o.value === v);
          return (
            <div onClick={() => remove(v)} key={idx}>
              {item.label}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Select;
