import React from "react";
import Input from "../components/Input";
import { useFormikContext } from "formik";
import Error from "./Error";

export default function FormInput({ name, select, ...others }) {
  const { setFieldTouched, errors, touched, setFieldValue, values } =
    useFormikContext();
  return (
    <>
      <Input
        {...others}
        onBlur={() => setFieldTouched(name)}
        onChange={(e) => setFieldValue(name, e.target.value)}
        value={values[name]}
      />
      <Error error={errors[name]} touched={touched[name]} />
    </>
  );
}
