import React from "react";
import "../css/card.css";

function Card({ title, children, style, className = "", ...props }) {
  return (
    <div {...props} className={`card ${className}`} style={style}>
      <h2>{title}</h2>
      {children}
    </div>
  );
}

export default Card;
