import React from "react";

function ScrollTop({ visible }) {
  if (!visible) return null;
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() =>
        document.getElementById("home").scrollIntoView({ behavior: "smooth" })
      }
      className="stop"
    >
      <svg
        style={{ transform: "scale(0.7)" }}
        fill="white"
        className="w-6 h-6"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
        ></path>
      </svg>
    </div>
  );
}

export default ScrollTop;
