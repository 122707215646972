import React from "react";

function SocialIcons({ className, ...props }) {
  return (
    <div className={`socials flex ${className}`} {...props}>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://web.facebook.com/CspillaiVentures/?_rdc=1&_rdr"
        className="icon flex justify-center align-center block"
      >
        <i className="fab fa-facebook"></i>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.linkedin.com/company/cspillai"
        className="icon flex justify-center align-center block"
      >
        <i className="fab fa-linkedin"></i>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://wa.me/+918850762306"
        className="icon flex justify-center align-center block"
      >
        <i className="fab fa-whatsapp"></i>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/cspillai4"
        className="icon flex justify-center align-center block"
      >
        <i className="fab fa-instagram"></i>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://twitter.com/csPILLAI4"
        className="icon flex justify-center align-center block"
      >
        <i className="fab fa-twitter"></i>
      </a>
    </div>
  );
}

export default SocialIcons;
