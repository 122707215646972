import React from "react";
import "../css/input.css";

function Input({ className, placeholder = "", type = "text", ...props }) {
  return (
    <div className={`input ${className}`}>
      <label htmlFor={placeholder.replace(" ", "_")}>{placeholder}</label>
      <input
        {...props}
        type={type}
        id={placeholder.replace(" ", "_")}
        placeholder={placeholder}
      />
    </div>
  );
}

export default Input;
