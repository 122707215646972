import React from "react";

function Check({ label, value, checked, handleChange, ...props }) {
  return (
    <div className="flex check">
      <input
        {...props}
        type="radio"
        checked={checked}
        onChange={() => handleChange(value)}
        id={value}
      />
      <label htmlFor={value}>{label}</label>
    </div>
  );
}

export default Check;
