import React, { useEffect, useState } from "react";
import states from "../utils/states";
import useClient from "../hooks/useClient";
import urls from "../api/urls";
import Card from "./Card";
import Form from "../form/Form";
import FormInput from "../form/FormInput";
import * as Yup from "yup";
import { FormSelect, Submit } from "../form";
import logo from "../assets/logotrasparent.png";

//5267 3181 8797 5449

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string().email().required().label("Email Address"),
  address: Yup.string().required().label("Home Address"),
  phoneNumber: Yup.string().required().label("Phone Numner"),
  password: Yup.string().required().label("Password"),
  products: Yup.array()
    .of(Yup.string())
    .required()
    .min(1)
    .label("Preferred Product"),
  financeOption: Yup.string().required().label("Finance Option"),
  gender: Yup.string().required().label("Gender"),
  location: Yup.string().required().label("State"),
  associate: Yup.string().label("Associate").nullable(),
});

function ReserveForm(props) {
  const [selectedState, setSelectedState] = useState(null);
  const [products, setProducts] = useState([]);
  const [associates, setAssociates] = useState([]);
  const { get } = useClient();
  const { post, isLoading } = useClient();
  const getProducts = async () => {
    const res = await get(urls.cadre.products);
    if (res.error) return;
    setProducts(res.data.data.products);
  };

  const getAssociates = async () => {
    const res = await get(urls.cadre.associates.baseUrl);
    if (res.error) return;
    setAssociates(res.data.data.associates);
  };

  useEffect(() => {
    getProducts();
    getAssociates();
  }, []);

  const openPaymentBox = async (result, value) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // Getting the order details back
    const order = result.data;

    const options = {
      key: "rzp_test_GiMet4B7DYQTQn", // Enter the Key ID generated from the Dashboard
      amount: order.amount.toString(),
      //   currency: currency,
      name: "csPILLAI",
      description: "Franchise Reservation",
      image: logo,
      order_id: order.id,
      handler: async function (payload) {
        const data = await post(
          `${urls.cadre.reservations.baseUrl}/${order._id}/verify`,
          {
            orderCreationId: order.id,
            razorpayPaymentId: payload.razorpay_payment_id,
            razorpayOrderId: payload.razorpay_order_id,
            razorpaySignature: payload.razorpay_signature,
          }
        );
        if (data.error) return alert(data.data.message);
        alert(data.data.message);
      },
      prefill: {
        name: `${value.firstName} ${value.lastName}`,
        email: value.email,
        contact: value.phoneNumber,
      },
      notes: {
        address: value.address,
      },
      theme: {
        color: "#10375c", //	5267 3181 8797 5449
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const hanldeSubmit = async (data) => {
    const res = await post(urls.cadre.reservations.reserve, data);
    if (res.error) return alert(res.error.message);
    openPaymentBox(res.data, data);
  };
  return (
    <Card id="reserveForm" title="Reserve your franchise online">
      <Form
        onSubmit={hanldeSubmit}
        validationSchema={validationSchema}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          address: "",
          products: [],
          phoneNumber: "",
          password: "",
          location: "",
          associate: null,
          financeOption: "",
          gender: "",
        }}
      >
        <div
          style={{
            justifyContent: "space-between",
            marginTop: 20,
            alignItems: "center",
          }}
          className="flex mkl"
        >
          <FormSelect
            many
            showSelected={false}
            style={{ width: "48%", marginTop: 0 }}
            options={products.map((s) => ({
              label: s.name,
              value: s._id,
            }))}
            label="Preferred Products"
            placeholder="Select Products"
            name="products"
          />
          <div className="mbm" style={{ width: "48%" }}>
            <h3 className="lbtx">Preferred Technology</h3>
            <p>
              Select up to 3 technologies in order of preference Preferred
              products and technologies are subjected to availability
            </p>
          </div>
        </div>
        <div className="inputs">
          <FormSelect
            onChange={(state) => setSelectedState(state)}
            options={states.map((s) => ({ label: s, value: s }))}
            label="Your location in the country"
            placeholder="Select State"
            name="location"
            setNull="associate"
          />
          <FormSelect
            options={
              selectedState
                ? associates
                    .filter((a) => a.state === selectedState.label)
                    .map((s) => ({ label: s.name, value: s._id }))
                : []
            }
            refresh
            name="associate"
            label="Associate"
            placeholder="Select Associate"
          />
        </div>
        <div className="foption">
          <h3 className="lbtx">Financing options</h3>
          <div className="flex ops">
            <FormSelect
              check
              name="financeOption"
              value="Cash"
              label="I will pay in cash"
            />
            <FormSelect
              check
              name="financeOption"
              value="Loan"
              label="I already borrowed a loan"
            />
            <FormSelect
              check
              value="Sponsor"
              name="financeOption"
              label="I need a sponsor"
            />
          </div>
        </div>
        <div className="pdetails">
          <h3 className="lbtx">Personal Details</h3>
          <p>Are you a woman investor?</p>
          <div className="flex">
            <FormSelect check name="gender" value="Female" label="Yes" />
            <FormSelect check value="Male" name="gender" label="No" />
          </div>
        </div>
        <div className="inputs">
          <FormInput placeholder="First Name" name="firstName" />
          <FormInput placeholder="Last Name" name="lastName" />
        </div>
        <FormInput type="email" name="email" placeholder="Email Address" />
        <FormInput placeholder="Home Address" name="address" />
        <div className="inputs">
          <FormInput placeholder="Phone Number" name="phoneNumber" />
          <FormInput type="password" placeholder="Password" name="password" />
        </div>
        <p style={{ marginBottom: 0 }}>
          Phone numbers won’t be sent to associates unless verified By clicking
          proceed to payment you agree to our{" "}
          <a href="http://me.com" target="_blank" rel="noopener noreferrer">
            terms and conditions
          </a>
        </p>
        <span className="nt">₹15.984 convenience fee is applicable</span>
        <Submit
          loading={isLoading}
          title="Proceed to payment"
          type="submit"
          className="fsubmit"
        />
      </Form>
      <p>
        Important: cspillai.com aims to provide the most accurate and updated
        franchise information possible. However, please note that franchise
        information may be changed, by the company and the stakeholders, without
        prior notice. If you spot a potential mistake, feel free to contact us
        at <a href="mailto:mail@cspillai.com">mail@cspillai.com</a>
      </p>
    </Card>
  );
}

export default ReserveForm;
